.achievement-card_wrapper__h4vRY {
    display: grid;
    position: relative; /* Ensures children can use absolute positioning */
    grid-template-columns: 1fr; /* Single column grid */
    grid-template-rows: 1fr; /* Single row grid */
    width: -moz-max-content;
    width: max-content; /* Adjusts to content size */
    height: -moz-max-content;
    height: max-content; /* Adjusts to content size */

    transform-style: preserve-3d;
    perspective: 1000px;

    cursor: pointer;
}

    @media (max-width: 768px) {
        .achievement-card_wrapper__h4vRY:nth-child(even) {
            padding-inline-start: var(--space-24);
        }

        .achievement-card_wrapper__h4vRY:nth-child(odd) {
            padding-inline-end: var(--space-24);
        }
    }

.achievement-card_card__xiEgw > div {
        block-size: 100%;
    }

@media (--scren-lt-lg) {

.achievement-card_card__xiEgw {
        margin-left: 24px
}
    }

.achievement-card_cardFront__rcbN0,
.achievement-card_cardBack__W3_SK {
    grid-column: 1 / -1; /* Both elements span the entire column */
    grid-row: 1 / -1;
    will-change: transform;
    background-color: var(--page-background, var(--composition-background-neutral, #fdf4eb));
    border-radius: var(--border-radius-lg);
}

.achievement-card_cardBack__W3_SK {
    opacity: 0;
    transform: rotateY(180deg);
    pointer-events: none;
}

.achievement-card_task__GHDTy {
    padding: var(--space-16);
    block-size: 100%;
    --background-hex: #fff;
    --card-background: color-mix(in srgb, var(--background-hex), transparent 95%);
}

.achievement-card_task__GHDTy.achievement-card_p1__m6EzS {
        --background-hex: var(--red-500);
    }

.achievement-card_task__GHDTy.achievement-card_p2__Nalpo {
        --background-hex: var(--orange-500);
    }

.achievement-card_task__GHDTy.achievement-card_p3__cIkMg {
        --background-hex: var(--blue-500);
    }

.achievement-card_circle__D7AD5.achievement-card_borderWidth__oyczj {
    /* Combine two classes for higher specificity */
    --circle-border-width: 1.5px;
    margin-block-start: var(--space-4);
}

.achievement-card_taskCircle__mrQJM {
    cursor: pointer;
    margin-block-start: var(--space-4);
}

.achievement-card_colorSecondary__CajP0 {
    color: var(--display-onlight-secondary);
}

.prefooter_prefooterContainer__CUZ4R {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    padding-block-end: var(--space-128);
    padding-block-start: var(--space-192);
    background: linear-gradient(180deg, rgba(243, 245, 240, 0) 1.41%, var(--red-100) 43.97%);
}

    .prefooter_prefooterContainer__CUZ4R .prefooter_prefooter__eufBR {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--space-12) var(--space-64);
        text-align: center;
    }

    .prefooter_prefooterContainer__CUZ4R .prefooter_prefooter__eufBR h2 {
            margin-block-end: var(--space-8);
        }

    .prefooter_prefooterContainer__CUZ4R .prefooter_prefooter__eufBR h3 {
            margin-block-end: var(--space-24);
            max-inline-size: 560px;
            color: var(--display-onlight-secondary);
        }

    @media (max-width: 959px) {.prefooter_prefooterContainer__CUZ4R {
        padding-block-end: var(--space-96)
}
    }

.blog-roll_blogRoll__41OLa {
    --gap: var(--space-12);

    position: relative;
    display: grid;
    grid-gap: var(--gap);
    gap: var(--gap);
    grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
}

    .blog-roll_blogRoll__41OLa > * {
        block-size: 100%;
    }

.blog-roll_large__Mj2_7 {
    --gap: var(--space-32);
}

.blog-roll_backgroundIllustration__psseC {
    position: absolute;
    top: 50%;
    left: 50%;
    inline-size: 100%;
    height: auto;
    transform: translateX(-50%) translateY(-50%) scale(1.15);
}

@media (max-width: 768px) {

.blog-roll_backgroundIllustration__psseC {
        display: none
}
    }

.blockquote_blockquote__1OwsI {
    position: relative;
    padding-inline-start: 0.875rem;
    color: var(--display-onlight-secondary);
}

    .blockquote_blockquote__1OwsI::before {
        content: "";
        border-left: 2px solid var(--elevated-border);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }

.blog-post_sidebar__ucZ1j {
    position: sticky;
    top: 6rem;
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
    block-size: -moz-fit-content;
    block-size: fit-content;
}

    @media (max-width: 959px) {.blog-post_sidebar__ucZ1j {
        display: none
}
    }

.blog-post_content__wdHYW {
    display: grid;
    grid-template-columns: 1fr minmax(0, 40.625rem) 1fr;
}

.blog-post_content__wdHYW > * {
        grid-column: 2;
    }

.blog-post_content__wdHYW em {
        font-style: italic;
    }

.blog-post_content__wdHYW figure,
    .blog-post_content__wdHYW aside {
        grid-column: 1 / span 3;
        margin-inline: 0;
    }

.blog-post_content__wdHYW figure,
    .blog-post_content__wdHYW img,
    .blog-post_content__wdHYW video {
        max-inline-size: 100%;
        block-size: auto;
    }

.blog-post_content__wdHYW figure {
        text-align: center;
    }

.blog-post_content__wdHYW figcaption {
        color: var(--display-onlight-secondary);
    }

/* Spacing */
.blog-post_content__wdHYW > * + *,
    .blog-post_content__wdHYW blockquote > * + * {
        margin-block-start: var(--flow-space, var(--space-32));
    }
.blog-post_content__wdHYW p {
        --flow-space: var(--space-24);
    }
.blog-post_content__wdHYW h2 {
        --flow-space: var(--space-48);
    }
.blog-post_content__wdHYW figcaption {
        margin-block-start: var(--space-16);
    }
.blog-post_content__wdHYW hr {
        margin-block-start: var(--space-32);
    }
/* explicit, because of component issue */
@media (max-width: 768px) {
.blog-post_content__wdHYW hr {
            margin-block-start: var(--space-24)
    }
        }
.blog-post_content__wdHYW iframe,
    .blog-post_content__wdHYW span {
        --flow-space: 0;
    }

/** Styling */
.blog-post_content__wdHYW a:not([class]) {
        color: var(--display-onlight-color-blue);
    }
.blog-post_content__wdHYW table {
        inline-size: 100%;
        border-collapse: collapse;
    }
.blog-post_content__wdHYW table tr {
            border-bottom: 1px solid var(--ui-border);
        }
.blog-post_content__wdHYW table td,
        .blog-post_content__wdHYW table th {
            padding: var(--space-16) var(--space-8);
        }
.blog-post_content__wdHYW table thead th {
            color: var(--display-onlight-color-red);
            font-weight: 600;
        }
.blog-post_content__wdHYW ul,
    .blog-post_content__wdHYW ol {
        margin-block-start: var(--space-24);
        padding-inline-start: var(--space-32);
    }
.blog-post_content__wdHYW ul {
        list-style: disc;
    }
.blog-post_content__wdHYW ul ul {
        list-style: circle;
    }
.blog-post_content__wdHYW ul ul ul {
        list-style: square;
    }
.blog-post_content__wdHYW ol {
        list-style: decimal;
    }
.blog-post_content__wdHYW ::marker {
        color: var(--display-onlight-color-red);
        font-weight: 600;
    }
.blog-post_content__wdHYW ol > li {
        padding-left: 0.25em;
    }
.blog-post_content__wdHYW > ul li::marker {
        font-size: 1.5em;
    }
.blog-post_content__wdHYW li + li,
    .blog-post_content__wdHYW ul ul,
    .blog-post_content__wdHYW ul ol,
    .blog-post_content__wdHYW ol ol,
    .blog-post_content__wdHYW ol ul {
        margin-block-start: var(--space-8);
    }
.blog-post_content__wdHYW h1,
    .blog-post_content__wdHYW h2,
    .blog-post_content__wdHYW h3,
    .blog-post_content__wdHYW h4 {
        scroll-margin-top: 90px;
    }

.blog-post_highlight__IwYDe {
    position: relative;
    padding-block-start: var(--space-96);
    padding-block-end: var(--space-64);
    min-block-size: 18rem;
}

.blog-post_highlight__IwYDe img {
        --scale: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(var(--scale));
        inline-size: 100%;
        block-size: auto;
        max-inline-size: 45.5rem;
        pointer-events: none;
    }

@media (max-width: 768px) {

.blog-post_highlight__IwYDe img {
            --scale: 1.5
    }
        }

.blog-post_highlight__IwYDe > * {
        position: relative;
        max-inline-size: 37.5rem;
        margin-inline: auto;
    }

.blog-post_highlight__IwYDe figcaption {
        color: var(--display-onlight-primary);
    }

.blog-post_cta__ouVtt {
    display: flex;
    gap: var(--space-48);
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color, #fff6f0);
}

@media (max-width: 768px) {

.blog-post_cta__ouVtt {
        flex-direction: column-reverse;
        gap: var(--space-24);
        text-align: center
}
    }

.blog-post_colorSecondary__xfd_P {
    color: var(--display-onlight-secondary);
}

.blog-post_markdownQuote__ydAE7 {
    position: relative;
    padding-inline-start: 0.875rem;
}

.blog-post_markdownQuote__ydAE7::before {
        content: "";
        border-left: 2px solid var(--elevated-border);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }

.rich-text-highlight_richTextHighlight__S7e5k {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: calc(var(--space-64) + var(--space-24)) 0 var(--space-64);
}

    @media (max-width: 480px) {.rich-text-highlight_richTextHighlight__S7e5k {
        margin: var(--space-48) 0
}
    }

    .rich-text-highlight_richTextHighlight__S7e5k img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-inline-size: 125%;
        max-inline-size: 125%;
        /* inline-size: 728px;
        min-inline-size: 728px;
        max-inline-size: 728px;
        block-size: 298px;
        min-block-size: 298px;
        max-block-size: 298px; */

        /* @media (--screen-lt-md) {
            min-inline-size: 125%;
            max-inline-size: 125%;
        } */
    }

    .rich-text-highlight_richTextHighlight__S7e5k blockquote {
        position: relative;
        min-block-size: 200px;
        max-inline-size: 602px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family-serif);
        font-style: italic;
        font-size: 22px;
        line-height: 39.6px;
    }

    @media (max-width: 768px) {

    .rich-text-highlight_richTextHighlight__S7e5k blockquote {
            max-inline-size: 351px;
            font-size: 20px;
            line-height: 36px
    }
        }

.styles_categoryList__t69Z6 {
    display: flex;
    gap: var(--space-12);
    justify-content: center;
}

@media (max-width: 768px) {

.styles_article__utEIY {
        overflow: hidden
}
    }

@media (min-width: 960px) {

.styles_container__BjoDm.styles_noToc__pc6Ln {
        grid-template-columns: auto minmax(0, 50rem) auto;
        gap: 0
}
    }

.styles_colorSecondary__JuY6j {
    color: var(--display-onlight-secondary);
}

.styles_colorTertiary__qra04 {
    color: var(--display-onlight-tertiary);
}

.styles_textCenter__aRiS8 {
    text-align: center;
}

.table_tableContainer__9zFSv {
    margin: var(--space-32) 0 var(--space-48);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

    .table_tableContainer__9zFSv::-webkit-scrollbar {
        display: none;
    }

.table_articleTable__xNMRK {
    width: 100%;
    table-layout: auto;
    text-align: left;
    border-collapse: collapse;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0;
    font-weight: 400;
}

.table_articleTable__xNMRK:-webkit-scrollbar {
        display: none;
    }

.table_articleTable__xNMRK caption {
        display: none;
    }

.table_articleTable__xNMRK * {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
    }

.table_articleTable__xNMRK thead {
        border-bottom: 1px solid var(--color-grey-03);
    }

.table_articleTable__xNMRK thead th {
            vertical-align: bottom;
            font-weight: 600;
            padding: var(--space-16);
            padding-right: 0;
            font-size: 0.75rem;
            line-height: 1.2;
            letter-spacing: 0.02em;
            font-weight: 500;
            text-transform: uppercase;
            color: var(--color-theme-primary, var(--color-td-primary));
            min-width: 120px;
        }

.table_articleTable__xNMRK thead th > * {
                font-size: inherit;
                line-height: inherit;
                letter-spacing: inherit;
                font-weight: inherit;
            }

.table_articleTable__xNMRK thead th:first-child {
                padding-left: 0;
            }

.table_articleTable__xNMRK thead th:last-child {
                padding-right: 0;
            }

.table_articleTable__xNMRK tbody td {
            vertical-align: top;
            padding: var(--space-16);
            padding-right: 0;
            border-left: 0;
            border-right: 0;
        }

.table_articleTable__xNMRK tbody td:first-child {
                padding-left: 0;
            }

.table_articleTable__xNMRK tbody td:last-child {
                padding-right: 0;
            }

.table_articleTable__xNMRK tbody tr {
            border-bottom: 1px solid var(--color-grey-03);
        }

.table_articleTable__xNMRK tbody tr:last-child {
                border-bottom-width: 0;
            }

.table_articleTable__xNMRK[data-first-bold] tbody tr td:first-child {
        font-weight: bold;
    }

@media (max-width: 480px) {
        .table_articleTable__xNMRK thead th:not(:last-child),
        .table_articleTable__xNMRK tbody td:not(:last-child) {
            border-right: 1px solid var(--color-grey-03);
        }
        .table_articleTable__xNMRK {
        margin-left: 0;
    }
}

.td_td__mae7B {
    border-radius: var(--radius-md);
    border: 1px solid var(--color-black-04);
    padding: var(--space-16);
}

    .td_td__mae7B p {
        font-size: var(--font-size-16);
        line-height: var(--line-height-24);
    }

    .td_td__mae7B p:not(:first-child) {
        margin-top: var(--space-16);
    }

    .td_td__mae7B a {
        position: relative;
        color: var(--blue-500);
        transition: color var(--animation-duration-350) var(--animation-ease-base);
    }

    .td_td__mae7B ul {
        padding-top: 0;
        padding-bottom: var(--space-8);
    }

    .td_td__mae7B li {
        margin-inline-start: -20px;
    }

    .td_td__mae7B blockquote {
        margin-top: var(--space-16);
        padding: var(--space-8);
        background-color: #fff9f3;
        color: var(--color-black-07);
    }

.tip-card_tipCard__LdcwX {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

    .tip-card_tipCard__LdcwX > hr:first-child {
        margin-block-start: var(--space-32);
        margin-block-end: var(--space-48);
    }

    .tip-card_tipCard__LdcwX > hr:last-child {
        margin-block-start: var(--space-48);
        margin-block-end: var(--space-24);
    }

.tip-card_content__XAPRe {
    max-inline-size: 600px;
}

.tip-card_colorSecondary__Mqz0a {
    color: var(--display-onlight-secondary);
}

.details_main__EE55N {
    display: flex;
    flex-direction: column;
    gap: var(--space-48);
    flex-grow: 1;
    max-inline-size: 650px;
}

    @media (max-width: 959px) {.details_main__EE55N {
        margin-inline: auto
}
    }

.details_features__6fGVR li {
        display: flex;
        gap: var(--space-12);
        align-items: flex-start;
        padding: var(--space-16) 0;
    }

.details_features__6fGVR li .details_iconWrapper__tTJqZ {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            margin-block-start: 5px;
        }

.details_features__6fGVR li .details_iconWrapper__tTJqZ svg {
                width: 24px;
                height: 24px;
            }

.details_features__6fGVR li:not(:last-child) {
            border-bottom: 1px solid var(--ui-border);
        }

.details_carousel__rtG8V {
    inline-size: 100%;
}

.details_carousel__rtG8V video {
        inline-size: 100%;
        block-size: auto;
    }

.details_screenshot__R5Rb7 {
    -o-object-fit: cover;
       object-fit: cover;
}

.prefooter_wrapper__M4DrZ {
    inline-size: 100%;
    background: linear-gradient(
        181.57deg,
        rgba(243, 245, 240, 0) 1.41%,
        var(--footer-background) 43.97%
    );
    padding: var(--space-192) 0 var(--space-128);
}

    @media (max-width: 768px) {.prefooter_wrapper__M4DrZ {
        padding: var(--space-96) 0 var(--space-96)
}
    }

    .prefooter_wrapper__M4DrZ em {
        font-family: var(--font-family-body);
        font-style: italic;
    }

.prefooter_section__RkYkX {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--space-192);
}

@media (max-width: 1120px) {

.prefooter_section__RkYkX {
        gap: var(--space-64)
}
    }

@media (max-width: 768px) {

.prefooter_section__RkYkX {
        gap: var(--space-48);
        flex-direction: column;
        text-align: center
}
    }

@media (max-width: 959px) {

.prefooter_illustration__qk8r5 {
        max-inline-size: 250px;
        height: auto
}
    }

.prefooter_cta__VAcYP {
    max-inline-size: 450px;
}

@media (min-width: 769px) {

.prefooter_cta__VAcYP {
        min-inline-size: 400px
}
    }

.prefooter_text__U9O92 {
    color: var(--display-onlight-secondary);
}

.post-card_category__atlOF:hover {
    text-decoration: underline;
}

.table-of-contents_tableOfContents__RdWBc {
    block-size: -moz-fit-content;
    block-size: fit-content;
    max-block-size: calc(100vh - (var(--space-96) * 2));
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
}

    .table-of-contents_tableOfContents__RdWBc::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 768px) {.table-of-contents_tableOfContents__RdWBc {
        position: static;
        block-size: auto;
        top: auto;
        top: initial
}
    }

.rich-text-subscribe_form__kjSoC {
    --marketist-link-color: var(--display-onlight-color-blue);

    max-inline-size: 28rem;
    margin: var(--space-32) auto 0;
    position: relative;
}

    .rich-text-subscribe_form__kjSoC input {
        --padding-inline-end: 25%;
    }

    .rich-text-subscribe_form__kjSoC button {
        position: absolute;
        top: var(--space-8);
        right: var(--space-8);
    }

.rich-text-subscribe_hug__9JVtb {
    max-inline-size: 40.625rem;
    margin-inline: auto;
    text-align: center;
    position: relative;
}

.rich-text-subscribe_colorSecondary__8kksX {
    color: var(--display-onlight-secondary);
}

.rich-text-subscribe_successState__ppw5y {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-sidebar_containerSidebar__b74SW {
    display: grid;
    grid-gap: var(--space-48);
    gap: var(--space-48);
}

    @media (min-width: 960px) {.container-sidebar_containerSidebar__b74SW {
        grid-template-columns: 15rem minmax(0, 1fr)
}

        .container-sidebar_containerSidebar__b74SW.container-sidebar_lg__L_FEQ {
            grid-template-columns: 17.5rem minmax(0, 1fr);
        }
    }

@media (min-width: 960px) {

.container-sidebar_main__DeTxx {
        grid-column: 2
}
    }

.icons_verified__CfY5Y {
  margin-left: 0.125rem;
  max-width: 20px;
  max-height: 20px;
  height: 1.25em;
  fill: currentColor;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: text-bottom;
}

.quoted-tweet-container_root__i3qme {
  width: 100%;
  overflow: hidden;
  border: var(--tweet-border);
  border-radius: 12px;
  margin: var(--tweet-quoted-container-margin);
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
  cursor: pointer;
}

.quoted-tweet-container_root__i3qme:hover {
  background-color: var(--tweet-quoted-bg-color-hover);
}

.quoted-tweet-container_article__d6Fju {
  position: relative;
  box-sizing: inherit;
}

.tweet-actions_actions__NR2hO {
  display: flex;
  align-items: center;
  color: var(--tweet-font-color-secondary);
  padding-top: 0.25rem;
  margin-top: 0.25rem;
  border-top: var(--tweet-border);
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tweet-actions_like__DgC87,
.tweet-actions_reply__5f0Na,
.tweet-actions_copy__RSkHw {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
}
.tweet-actions_like__DgC87:hover,
.tweet-actions_reply__5f0Na:hover,
.tweet-actions_copy__RSkHw:hover {
  background-color: rgba(0, 0, 0, 0);
}
.tweet-actions_like__DgC87:hover > .tweet-actions_likeIconWrapper__t8GHU {
  background-color: var(--tweet-color-red-primary-hover);
}
.tweet-actions_like__DgC87:hover > .tweet-actions_likeCount__h03yG {
  color: var(--tweet-color-red-primary);
  text-decoration-line: underline;
}
.tweet-actions_likeIconWrapper__t8GHU,
.tweet-actions_replyIconWrapper__ZbPqU,
.tweet-actions_copyIconWrapper__9_YfP {
  width: var(--tweet-actions-icon-wrapper-size);
  height: var(--tweet-actions-icon-wrapper-size);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.25rem;
  border-radius: 9999px;
}
.tweet-actions_likeIcon__HxQ4b,
.tweet-actions_replyIcon__ouTrj,
.tweet-actions_copyIcon__yZJU7 {
  height: var(--tweet-actions-icon-size);
  fill: currentColor;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.tweet-actions_likeIcon__HxQ4b {
  color: var(--tweet-color-red-primary);
}
.tweet-actions_likeCount__h03yG,
.tweet-actions_replyText__HHqRG,
.tweet-actions_copyText__TCfoP {
  font-size: var(--tweet-actions-font-size);
  font-weight: var(--tweet-actions-font-weight);
  line-height: var(--tweet-actions-line-height);
  margin-left: 0.25rem;
}

.tweet-actions_reply__5f0Na:hover > .tweet-actions_replyIconWrapper__ZbPqU {
  background-color: var(--tweet-color-blue-secondary-hover);
}
.tweet-actions_reply__5f0Na:hover > .tweet-actions_replyText__HHqRG {
  color: var(--tweet-color-blue-secondary);
  text-decoration-line: underline;
}
.tweet-actions_replyIcon__ouTrj {
  color: var(--tweet-color-blue-primary);
}

.tweet-actions_copy__RSkHw {
  font: inherit;
  background: none;
  border: none;
  cursor: pointer;
}
.tweet-actions_copy__RSkHw:hover > .tweet-actions_copyIconWrapper__9_YfP {
  background-color: var(--tweet-color-green-primary-hover);
}
.tweet-actions_copy__RSkHw:hover .tweet-actions_copyIcon__yZJU7 {
  color: var(--tweet-color-green-primary);
}
.tweet-actions_copy__RSkHw:hover > .tweet-actions_copyText__TCfoP {
  color: var(--tweet-color-green-primary);
  text-decoration-line: underline;
}

.tweet-container_root__wzLwj {
  width: 100%;
  min-width: 250px;
  max-width: 550px;
  overflow: hidden;
  /* Base font styles */
  color: var(--tweet-font-color);
  font-family: var(--tweet-font-family);
  font-weight: 400;
  box-sizing: border-box;
  border: var(--tweet-border);
  border-radius: 12px;
  margin: var(--tweet-container-margin);
  background-color: var(--tweet-bg-color);
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
}
.tweet-container_root__wzLwj:hover {
  background-color: var(--tweet-bg-color-hover);
}
.tweet-container_article__jIFmm {
  position: relative;
  box-sizing: inherit;
  padding: 0.75rem 1rem;
}

.product-ui-avatar_container__dn7IC {
    position: relative;
}

    .product-ui-avatar_container__dn7IC .product-ui-avatar_checkmark__zOFmJ {
        position: absolute;
        bottom: -2px;
        right: -2px;
    }

    .product-ui-avatar_container__dn7IC .product-ui-avatar_count__nl0GK {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        inset: 0;
        background: var(--display-onlight-secondary);
        border-radius: inherit;
    }

    .product-ui-avatar_container__dn7IC .product-ui-avatar_count__nl0GK p {
            color: #fff;
        }

.product-ui-avatar_avatarStack__WtCa5 {
    display: flex;
}

.product-ui-avatar_avatarStack__WtCa5 > *:not(:first-child) {
        margin-left: calc(-1 * var(--space-8));
    }

.product-ui-avatar_avatarStack__WtCa5 > * {
        border-radius: 50%;
        box-shadow: 0 0 0 1px var(--composition-background-white);
    }

.setup-card_cardContainer__SVfuA {
    block-size: 100%;
}

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau {
        display: flex;
        position: relative;
        block-size: 100%;
        min-inline-size: 310px;
    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-inline-size: 130px;
            background-color: var(--bg-color, white);
            border-top-left-radius: var(--card-border-radius);
            border-bottom-left-radius: var(--card-border-radius);
        }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC img {
                -o-object-fit: cover;
                   object-fit: cover;
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC > hr {
                position: absolute;
                inline-size: 1px;
                block-size: 100%;
                top: 0;
                right: 0;
                bottom: 0;
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_link___nA_z {
                outline: 0;
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_link___nA_z:hover {
                    text-decoration: none;
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_link___nA_z::after {
                    position: absolute;
                    content: "";
                    inset: 0;
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_description__408RF {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                color: var(--display-onlight-secondary);
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm {
                display: flex;
                flex-direction: column;
                color: var(--display-onlight-secondary);
                gap: var(--space-8);
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_templateType__ZOnil {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_templateType__ZOnil .setup-card_icon__C_0uU {
                        block-size: var(--space-24);
                        inline-size: var(--space-24);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_templateType__ZOnil .setup-card_icon__C_0uU svg {
                            color: var(--display-onlight-tertiary);
                        }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E {
                    display: flex;
                    flex-direction: column;
                    gap: var(--space-4);
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E > div {
                        display: flex;
                        align-items: center;
                        inline-size: 100%;
                    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E .setup-card_icon__C_0uU {
                        block-size: var(--space-24);
                        inline-size: var(--space-24);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E .setup-card_icon__C_0uU svg {
                            color: var(--display-onlight-tertiary);
                        }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E p {
                        padding-inline-start: 6px;
                    }

    .setup-card_cardContainer__SVfuA.setup-card_forceNarrow__ayCaZ {
        container-name: narrowed;
        container-type: inline-size;
    }

    @media (max-width: 768px) {.setup-card_cardContainer__SVfuA {
        container-name: narrowed;
        container-type: inline-size
}
    }

    @container narrowed (min-width: 0px) {
        .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau {
            flex-direction: column;
            min-block-size: 290px;
            min-inline-size: unset;
        }

            .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC {
                min-inline-size: 100%;
                block-size: 125px;
                border-top-right-radius: var(--card-border-radius);
                border-bottom-left-radius: 0;
                border-bottom-left-radius: initial;
            }

                .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC > hr {
                    inline-size: 100%;
                    block-size: 1px;
                    top: auto;
                    top: initial;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

            .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E {
                display: flex;
                flex: 1 1;
                flex-direction: column;
                justify-content: space-between;
            }

                .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm {
                    flex-direction: row;
                }

                    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm > hr {
                        block-size: var(--space-24);
                        inline-size: 1px;
                    }

                    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E {
                        gap: var(--space-4);
                        flex-direction: row;
                    }

                    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm p {
                        padding-inline-start: 2px;
                    }
    }

.template-icon-card_accessoryView__prUcg {
    color: var(--display-onlight-secondary);
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.template-icon-card_icon__cm3N4 {
    block-size: var(--space-24);
    inline-size: var(--space-24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block-start: 1px;
}

.template-icon-card_icon__cm3N4 svg {
        color: var(--display-onlight-tertiary);
    }

.product-ui-skeleton-text_skeletonText__U3_IB {
    display: grid;
    place-items: center;
    inline-size: var(--inlineSize);
    block-size: var(--blockSize);
}

    .product-ui-skeleton-text_skeletonText__U3_IB::before,
    .product-ui-skeleton-text_skeletonText__U3_IB::after {
        grid-row: 1;
        grid-column: 1;
    }

    .product-ui-skeleton-text_skeletonText__U3_IB::before {
        display: block;
        content: " ";
        inline-size: 100%;
        block-size: 100%;
        background-color: var(--bg-color, var(--product-ui-display-onlight-skeleton));
        border-radius: 3px;
    }

    .product-ui-skeleton-text_skeletonText__U3_IB.product-ui-skeleton-text_onColor__tqnJD::before {
            --bg-color: var(--product-ui-display-oncolor-skeleton);
        }

.product-ui-skeleton-text_striken__GaA8H::after {
        display: block;
        content: " ";
        inline-size: calc(100% + 8px);
        block-size: 1px;
        background-color: var(--ui-border);
    }

.product-ui-button_button__YsLx_ {
    --product-ui-button-gap: 0;
    --product-ui-button-border: none;
    --product-ui-button-padding: 0 8px;
    --product-ui-button-border-radius: var(--border-radius-base);
    --product-ui-button-block-size: 36px;
    --product-ui-button-color: #fff;
    --product-ui-button-background-color: var(--productui-primary-theme-color);
    --product-ui-button-text-padding: 0 7px;
    --product-ui-button-icon-size: var(--space-24);

    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: var(--product-ui-button-gap);
    border: var(--product-ui-button-border);
    border-radius: var(--product-ui-button-border-radius);
    padding: var(--product-ui-button-padding);
    block-size: var(--product-ui-button-block-size);
    color: var(--product-ui-button-color);
    background-color: var(--product-ui-button-background-color);
}

    .product-ui-button_button__YsLx_ > div {
        padding: var(--product-ui-button-text-padding);
    }

    .product-ui-button_button__YsLx_.product-ui-button_secondary__3jiFV {
        --product-ui-button-color: var(--display-onlight-primary);
        --product-ui-button-background-color: var(--actionable-secondary-background-idle);
    }

    .product-ui-button_button__YsLx_.product-ui-button_quaternary__cbdh7 {
        --product-ui-button-color: var(--display-onlight-secondary);
        --product-ui-button-background-color: var(--actionable-quaternary-background-idle);
    }

    .product-ui-button_button__YsLx_.product-ui-button_quinary__4_4_0 {
        --product-ui-button-gap: 6px;
        --product-ui-button-padding: 0;
        --product-ui-button-block-size: 31px;
        --product-ui-button-text-padding: 0;
        --product-ui-button-color: var(--display-onlight-tertiary);
        --product-ui-button-background-color: var(--actionable-quinary-background-idle);
    }

    .product-ui-button_button__YsLx_.product-ui-button_outline__FNHvz {
        --product-ui-button-padding: 0 var(--space-4);
        --product-ui-button-gap: 0;
        --product-ui-button-icon-size: 18px;
        --product-ui-button-border-radius: var(--border-radius-sm);
        --product-ui-button-color: var(--display-onlight-tertiary);
        --product-ui-button-background-color: var(--actionable-quaternary-background-idle);
        --product-ui-button-border: 1px solid var(--ui-border);
        --product-ui-button-block-size: var(--tube-height-xs);
        --product-ui-button-text-padding: 0 var(--space-4);
    }

.product-ui-button_hovered__a0M_N {
    --product-ui-button-background-color: var(--actionable-primary-background-onhover);
}

.product-ui-button_hovered__a0M_N.product-ui-button_secondary__3jiFV {
        --product-ui-button-background-color: var(--actionable-secondary-background-onhover);
    }

.product-ui-button_hovered__a0M_N.product-ui-button_quaternary__cbdh7,
    .product-ui-button_hovered__a0M_N.product-ui-button_quinary__4_4_0,
    .product-ui-button_hovered__a0M_N.product-ui-button_outline__FNHvz {
        --product-ui-button-color: var(--display-onlight-primary);
        --product-ui-button-background-color: var(--actionable-quaternary-background-onhover);
    }

.product-ui-button_hovered__a0M_N.product-ui-button_quinary__4_4_0 {
        --product-ui-button-background-color: none;

        position: relative;
        z-index: 0;
    }

.product-ui-button_hovered__a0M_N.product-ui-button_quinary__4_4_0:before {
            content: "";
            position: absolute;
            top: 0;
            left: -9px;
            right: -9px;
            bottom: 0;
            border-radius: var(--product-ui-button-border-radius);
            background-color: var(--actionable-quinary-background-onhover);
            z-index: -1;
        }

.product-ui-button_icon__K0uEC {
    flex-grow: 0;
    flex-shrink: 0;
}

.product-ui-context-menu_contextMenu__C7WOX {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    min-inline-size: var(--context-menu-width, 0);

    position: absolute;
    top: var(--context-menu-top, unset);
    left: var(--context-menu-left, unset);
    bottom: var(--context-menu-bottom, unset);
    right: var(--context-menu-right, unset);

    padding: var(--context-menu-padding, var(--space-8));
    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-xl);
    z-index: var(--product-ui-z-index-popup);
}

.product-ui-context-menu_contextMenuHeading__Axt0h {
    color: var(--display-onlight-primary);
    padding-inline: var(--space-8);
    padding-block-start: 6px;
    padding-block-end: var(--space-4);
}

.product-ui-context-menu_header__ZH_wv {
    margin-inline: var(--space-8);
    margin-block-start: 0.375rem;
    margin-block-end: var(--space-4);
    color: var(--display-onlight-secondary);
}

.product-ui-context-menu_contextMenuItem__buz6T {
    display: flex;
    align-items: center;
    gap: var(--space-8);
    padding: var(--space-4);
    padding-inline: var(--space-8);
    min-block-size: var(--tube-height-sm);
}

.product-ui-context-menu_listItemContainer__QgYZ7 {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    color: var(--display-onlight-primary);
}

.product-ui-list-item_container__7EEiF {
    --height: var(--tube-height-sm);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-8);
    --padding: var(--space-4) var(--space-8);

    padding: var(--padding);
    min-block-size: var(--height);
    inline-size: 100%;
    border-radius: var(--border-radius);
    position: relative;

    display: flex;
    align-items: center;
    gap: var(--gap);
}

    .product-ui-list-item_container__7EEiF .product-ui-list-item_right__AibKW,
    .product-ui-list-item_container__7EEiF .product-ui-list-item_left__xxBmx {
        display: flex;
        align-items: var(--left-right-align-items, center);
        gap: var(--left-right-gap, var(--space-8));
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_right__AibKW {
        flex: var(--right-flex, inherit);
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_left__xxBmx {
        color: var(--display-onlight-primary);
        flex: var(--left-flex, auto);
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_left__xxBmx svg {
            flex-shrink: 0;
        }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_subLabel__Mxzpg {
        color: var(--display-onlight-secondary);
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_icon__eFXc4 {
        transition: transform var(--animation-duration-350) var(--animation-ease-base);
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_open__G5LZc {
        transform: rotate(-0.25turn);
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_stack__9uD_8 {
        display: flex;
        flex-direction: column;
        flex: auto;
        gap: var(--space-4);
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_abstract__osOji {
        inline-size: 100%;
    }

    .product-ui-list-item_container__7EEiF .product-ui-list-item_row___sKrt {
        flex-direction: row;
    }

    .product-ui-list-item_container__7EEiF:has(.product-ui-list-item_projectStatus__RF0Es) {
        --right-flex: 1;
        --left-flex: 1;
    }
.product-ui-list-item_basicType__wY2gB {
    --height: var(--tube-height-sm);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-8);
    --padding: var(--space-4) var(--space-8);
}

.product-ui-list-item_sideBarType__pwQ_D {
    --height: var(--tube-height-sm);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-8);
    --padding: var(--space-4) var(--space-8);
    --list-item-text-active: var(--display-onlight-primary);
}

.product-ui-list-item_sideBarType__pwQ_D .product-ui-list-item_left__xxBmx {
        color: var(--display-onlight-secondary);
    }

.product-ui-list-item_projectType__RXSDS {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-xl);
    --padding: var(--space-12) 15px;
    --gap: var(--space-12);
}

.product-ui-list-item_projectType__RXSDS .product-ui-list-item_left__xxBmx > div {
        flex: 1 1;
    }

.product-ui-list-item_permissionType__LsNlU {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-xl);
    --gap: var(--space-12);
    --padding: 9.5px var(--space-12);
}

.product-ui-list-item_permissionType__LsNlU .product-ui-list-item_left__xxBmx {
        --left-right-gap: var(--space-12);
        --left-flex: 0;
    }

.product-ui-list-item_permissionType__LsNlU .product-ui-list-item_middle__lM9Vt {
        flex: 1 1;
        display: flex;
        gap: var(--space-4);
        flex-direction: column;
    }

.product-ui-list-item_activityType__pG11g {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-xl);
    --gap: var(--space-12);
    --padding: 6px var(--space-12);
}

.product-ui-list-item_roleType__yQXsp {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-16);
    --padding: var(--space-8);
}

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_left__xxBmx {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        flex: 1 1;
        gap: var(--space-4);
    }

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_middle__lM9Vt {
        flex: 1 1;
    }

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_right__AibKW {
        inline-size: 24px;
    }

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_icon__eFXc4 {
        flex: 0 1;
    }

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_icon__eFXc4 svg {
        inline-size: 24px;
        block-size: 24px;
        color: var(--display-onlight-tertiary);
    }

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_subtitle__0JWUJ {
        color: var(--display-onlight-secondary);
    }

.product-ui-list-item_roleType__yQXsp .product-ui-list-item_stack__9uD_8 {
        gap: 0;
    }

.product-ui-list-item_hovered__QjPYg {
    background-color: var(
        --list-item-background-hover,
        var(--actionable-quaternary-background-onhover)
    );
}

.product-ui-list-item_hovered__QjPYg .product-ui-list-item_left__xxBmx {
        color: var(--display-onlight-primary);
    }

.product-ui-list-item_active__7PusF {
    background-color: var(
        --list-item-background-active,
        var(--productui-sidebar-actionable-quaternary-background-active)
    );
}

.product-ui-list-item_active__7PusF .product-ui-list-item_left__xxBmx {
        color: var(--list-item-text-active, var(--productui-primary-theme-color));
    }

.product-ui-list-item_listItemWrapper__0I3g8 {
    position: relative;
}

.product-ui-list-item_sidebarListItem__nVafi {
    --list-item-background-active: var(--productui-sidebar-actionable-quaternary-background-active);
}

.product-ui-list-item_sidebarListItem__nVafi:has(.product-ui-list-item_contextMenuListItemRight__vEJl9) {
        --left-flex: 0 1 auto;
    }

.product-ui-list-item_sidebarListItem__nVafi.product-ui-list-item_active__7PusF:not(.product-ui-list-item_sideBarType__pwQ_D) .product-ui-list-item_left__xxBmx {
        color: var(--productui-display-color);
    }

.product-ui-list-item_label__TBzLF {
    display: flex;
    align-items: center;
    gap: var(--space-2);
}

.product-ui-list-item_label__TBzLF .product-ui-list-item_locked__cLDWh {
        flex: 0 1;
        inline-size: var(--icon-base);
        block-size: var(--icon-base);
        color: var(--display-onlight-tertiary);
    }

.product-ui-list-item_joinLabel__dY46M {
    margin-block-start: var(--space-2);
    color: var(--display-onlight-color-green);
}

.product-ui-list-item_subtitle__0JWUJ {
    margin-block-start: var(--space-2);
    color: var(--display-onlight-secondary);
}

.product-ui-list-item_folderListItem__7wX8W {
    display: flex;
    align-items: baseline;
    margin-inline-start: calc(var(--space-24) * -1);
}

.product-ui-list-item_folderListItemContent__ALS3X {
    inline-size: 100%;
}

.product-ui-list-item_folderListItemHeader__keXVC {
    display: flex;
    align-items: center;
}

.product-ui-list-item_folderListItemHeader__keXVC > .product-ui-list-item_container__7EEiF {
        --padding: 6px var(--space-12);
    }

.product-ui-list-item_folderListItemChildren__JV_m4 {
    margin-inline-start: var(--space-32);
}

.product-ui-list-item_folderListItemChildren__JV_m4 > .product-ui-list-item_container__7EEiF {
        --padding: 6px var(--space-12);
    }

.product-ui-list-item_contextMenuListItemRight__vEJl9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-4);
    color: var(--display-onlight-secondary);
}

.product-ui-list-item_contextMenuListItemRight__vEJl9 svg {
        color: var(--display-onlight-tertiary);
    }

.product-ui-list-item_projectStatus__RF0Es {
    display: flex;
    align-items: center;
    gap: var(--space-12);
}

.product-ui-list-item_projectStatus__RF0Es .product-ui-list-item_statuses__ypWm2 {
        display: flex;
        align-items: center;
        gap: var(--space-8);
    }

.product-ui-list-item_taskStatus__HjV_4 {
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.product-ui-list-item_taskStatus__HjV_4 p {
        color: var(--task-status-color);
    }

.product-ui-calendar-month-popover_taskContainer__qsnQV {
    display: flex;
    align-items: center;
    gap: var(--space-12);
    block-size: var(--tube-height-sm);
}

.product-ui-calendar-month-popover_item__FePHy {
    display: flex;
    gap: var(--space-12);
    align-items: center;
    block-size: var(--tube-height-sm);
}

.product-ui-calendar-month-popover_badge__ZhGSR {
    border-radius: var(--border-radius-sm);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--space-4) var(--space-8);
}

.product-ui-comment_comment__tgYQg {
    display: flex;
    align-items: flex-start;
    gap: var(--space-16);

    inline-size: 100%;
}

    .product-ui-comment_comment__tgYQg .product-ui-comment_content__tReT_ {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-12);

        inline-size: 100%;
    }

    .product-ui-comment_comment__tgYQg .product-ui-comment_copy__SLk2r {
        display: flex;
        flex-direction: column;
        gap: var(--space-8);

        inline-size: 100%;
    }

    .product-ui-comment_comment__tgYQg .product-ui-comment_chips__BKXkC {
        display: flex;
        align-items: center;
        gap: var(--space-4);
    }

    .product-ui-comment_comment__tgYQg .product-ui-comment_name__SiDkP {
        display: flex;
        align-items: center;
        gap: var(--space-8);
    }

.product-ui-comment_text__5zwJg {
    font-weight: var(--comment-font-weight, 400);
    -webkit-text-decoration: var(--comment-text-decoration, none);
            text-decoration: var(--comment-text-decoration, none);
    color: var(--comment-color, var(--display-onlight-color-primary));
    line-height: 1.75;
}

.product-ui-comment_strong__xydWM {
    --comment-font-weight: 600;
}

.product-ui-comment_link__5_IlC {
    --comment-text-decoration: underline;
}

.product-ui-comment_tertiary__OeS_K {
    --comment-color: var(--display-onlight-tertiary);
}

.product-ui-comment_reactionChip__93eSk {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-8);

    padding: 5px 10px;
    min-block-size: var(--tube-height-xs);

    border-radius: var(--border-radius-full);
    background-color: var(--background-color, var(--actionable-secondary-background-idle));
}

.product-ui-comment_reactionChip__93eSk.product-ui-comment_selected__j_9T4 {
        border: 1px solid var(--productui-meta-blue);
        color: var(--display-onlight-color-blue);
    }

.product-ui-comment_reactionChip__93eSk.product-ui-comment_onhover__FR7fS {
        --background-color: var(--actionable-secondary-background-onhover);
    }

.product-ui-comment_reactionChip__93eSk > * {
        line-height: 1;
    }

.product-ui-comment_reactionAdd__zz_cD {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: 5px;

    border-radius: var(--border-radius-full);
    background-color: var(--background-color, var(--actionable-secondary-background-idle));
}

.product-ui-comment_reactionAdd__zz_cD.product-ui-comment_onhover__FR7fS {
        --background-color: var(--actionable-secondary-background-onhover);
    }

.product-ui-comment-field_expandedContainer__sevGb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-12);
    inline-size: 100%;

    padding: 15px var(--space-16);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--field-outline-border-onhover);
    background: var(--field-outline-background-onhover);
}

.product-ui-comment-field_container__hFOer {
    display: flex;
    align-items: center;
    gap: var(--space-12);
    inline-size: 100%;
}

.product-ui-comment-field_container__hFOer .product-ui-comment-field_commentField__sQ1Ui {
        block-size: var(--tube-height-sm);
        padding: 0 var(--space-16);
        border-radius: var(--border-radius-full);
        border: 1px solid var(--field-outline-border-idle);
        background: var(--field-outline-background-idle);
    }

.product-ui-comment-field_hovered__v42ms .product-ui-comment-field_commentField__sQ1Ui {
        background-color: var(--ui-background);
    }

.product-ui-comment-field_hovered__v42ms .product-ui-comment-field_commentField__sQ1Ui * {
            color: var(--display-onlight-primary);
        }

.product-ui-comment-field_commentField__sQ1Ui {
    display: flex;
    inline-size: 100%;
    align-items: center;
}

.product-ui-comment-field_chips__qg4r5 {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-8);
    align-items: center;
}

.product-ui-comment-field_chip__oN2nA {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-8);

    padding-inline-end: 7px;
    border-radius: var(--border-radius-full);
    background-color: var(--ui-background);
}

.product-ui-comment-field_chip__oN2nA .product-ui-comment-field_avatar__ulZwU {
        border-radius: var(--border-radius-full);
    }

.product-ui-comment-field_footerSection__oGxfP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    inline-size: 100%;
}

.product-ui-comment-field_footerSection__oGxfP .product-ui-comment-field_buttons__qeFWI {
        display: flex;
        gap: var(--space-8);
    }

.product-ui-field_field__5od2J {
    display: flex;
    block-size: 36px;
    padding: 0 var(--space-8);
    align-items: center;
    gap: var(--space-8);

    border-radius: var(--border-radius-sm);
    border: 1px solid var(--field-outline-border-idle);
    background: var(--field-outline-background-idle);
}

    .product-ui-field_field__5od2J.product-ui-field_disabled__JRnty {
        opacity: 0.4;
    }

    .product-ui-field_field__5od2J.product-ui-field_hovered__13UY2 {
        border-color: var(--field-outline-border-onhover);
    }

.product-ui-field_placeholder__I9qtj {
    color: var(--display-onlight-tertiary);
}

.product-ui-field_children__0wOtR {
    flex: 1 1;
}

.product-ui-field_fields__j5zuC {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

.product-ui-frame-background_framebackground__t11E7 {
    background-color: var(--light-color, var(--productui-background-light-red));
}

.product-ui-frame-background_lightColorFill__IHcDJ {
    fill: var(--light-color, var(--productui-background-light-red));
}

.product-ui-frame-background_darkColorFill__scJBY {
    fill: var(--dark-color, var(--productui-background-dark-red));
}

.product-ui-frame-background_image__UioT5 {
    inline-size: 100%;
    block-size: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

.hero_hero__J1NFM {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--space-32);

    --product-ui-inline-size: 31.25rem; /* 500px */
    --product-ui-block-size: 29.125rem;
} /* 466px */

    @media (min-width: 769px) {.hero_hero__J1NFM {
        --product-ui-inline-size: 82rem; /* 1312px */
        --product-ui-block-size: 46.125rem /* 738px */
}
    }

    @media (min-width: 1120px) {.hero_hero__J1NFM {
        --product-ui-inline-size: 39.5rem; /* 632px */
        --product-ui-block-size: 22.25rem; /* 356px */

        flex-direction: row;
        gap: var(--space-48)
}
    }

    @media (min-width: 1200px) {.hero_hero__J1NFM {
        --product-ui-inline-size: 45.5rem; /* 712px */
        --product-ui-block-size: 22.25rem /* 356px */
}
    }

.hero_centered__tfrnI {
    align-items: center;
}

.hero_details__quLR0 {
    max-inline-size: 500px;
    margin-inline-start: 66px;
}

.hero_details__quLR0 p {
        color: var(--display-onlight-secondary);
    }

@media (max-width: 1120px) {

.hero_details__quLR0 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-inline-size: 650px;
        margin-inline: auto
}
    }

.hero_illustration__UFeKS {
    flex-shrink: 0;
    align-items: center;
    max-inline-size: 100%;
}

.hero_imageContainer__eIT1j {
    max-inline-size: 100%;

    --img-inline-size: var(--product-ui-inline-size);
    --img-block-size: auto;

    position: relative;
    inline-size: var(--img-inline-size);

    overflow: hidden;
    background-color: var(--elevated-background);
    border: 0.5px solid var(--greytransparent-200);
    border-radius: var(--product-frame-border-radius, 15px);
    display: flex;
    align-items: flex-end;
}

.hero_imageContainer__eIT1j .hero_backgroundImage__R__D1 {
        position: absolute;
        inset: 0;
        inline-size: var(--img-inline-size);
        block-size: var(--img-block-size);
    }

.hero_imageContainer__eIT1j .hero_foregroundImage__qSBWY {
        max-inline-size: 100%;
        position: relative;
        inline-size: var(--img-inline-size);
        block-size: var(--img-block-size);
    }

.hero_mobile__24rPS {
    display: none;
}
@media (max-width: 768px) {
    .hero_desktop__8bDQW {
        display: none;
    }
    .hero_mobile__24rPS {
        display: block;
    }
}

.apps-section_galleryContainer__hFQNQ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--desktop-gap, var(--space-24));
}

.apps-section_imageContainer__Db2xk {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    margin: auto;
    aspect-ratio: 2 / 1;
    inline-size: 100%;
    border-radius: var(--border-radius-2xl);
    border: 0.5px solid var(--ui-border);
    overflow-y: hidden;
}

.apps-section_imageContainer__Db2xk > * {
        grid-column: 1;
        grid-row: 1;
    }

.apps-section_mobileGalleryContainer__Y2D4Y {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    inline-size: 100%;
}

.apps-section_background__cHZ13 {
    aspect-ratio: 2 / 1;
}

.apps-section_image__7zReV {
    inline-size: 100%;
    block-size: auto;

    transition: opacity var(--animation-duration-600) var(--animation-ease-base);
    z-index: 2;
}

.apps-section_image__7zReV img {
        aspect-ratio: 2 / 1;
    }

.apps-section_image__7zReV.apps-section_active__gfqzg {
        opacity: 1;
    }

.apps-section_image__7zReV:not(.apps-section_active__gfqzg) {
        opacity: 0;
    }

.apps-section_controlContainer___Lr5c {
    text-align: center;
}

.apps-section_alertSection__2MqsC {
    text-align: center;
}

.apps-section_title__55n6E {
    display: flex;
    justify-content: center;
}

.product-ui-context_container__AURMo {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
}

    .product-ui-context_container__AURMo > * {
        grid-column: 1;
        grid-row: 1;
    }

    .product-ui-context_container__AURMo:hover {
        --overlay-visible: visible;
    }

    .product-ui-context_container__AURMo.product-ui-context_completed__AOQ6I {
        --overlay-visible: visible;
    }

.product-ui-context_controls__HSG3u {
    place-self: center;
    z-index: var(--product-ui-z-index-controls);
    visibility: var(--overlay-visible, hidden);
}

.product-ui-cursor_productUICursor__rccuH {
    position: absolute;
    inline-size: var(--size, 44px);
    block-size: var(--size, 44px);
    background-color: var(--ui-background);
    -webkit-backdrop-filter: blur(0.5px);
            backdrop-filter: blur(0.5px);
    border: 2px solid var(--ui-border);
    border-radius: var(--border-radius-full);
    box-shadow: 0 1px 5px 0 rgba(42, 29, 48, 0.11);
    z-index: var(--product-ui-z-index-mouse);
}

.product-ui-cursor_lg__pB14D {
    --size: 52px;
}

.product-ui-cursor_productUICursorContainer__v9GrI {
    position: relative;
}

.product-ui-cursor_productUICursorContainer__v9GrI > .product-ui-cursor_productUICursor__rccuH {
        inset-inline-start: 50%;
        inset-block-start: 50%;
        translate: -50% -50%;
    }

.product-ui-frame_productFrame__02sSq {
    --product-ui-font: var(--font-family-body);
    --major-base-font-family: var(--product-ui-font);
    --major-sm-font-family: var(--product-ui-font);
    --major-base-min-size: 1.4375; /* 23px */
    --major-base-max-size: 1.625; /* 26px */
    --body-sm-max-size: 0.96875; /* 15.5px */
    --font-weight-bold: 675;
    --font-weight-semibold: 600;
    /* This isn't used here, it was to force the css file to change because of https://github.com/Doist/Issues/issues/13543 */
    --force-rebuild: 1;

    position: relative;
    overflow: hidden;
    font-family: var(--product-ui-font);

    pointer-events: var(--product-ui-pointer-events, none);
    border-radius: var(--product-frame-border-radius, 15px);
}

.product-ui-frame_productUIFrame__HXojZ {
    position: relative;
    inline-size: var(--product-ui-inline-size, 36.875rem); /* 590px */
    block-size: var(--product-ui-block-size, 34.375rem); /* 550px */
    aspect-ratio: 590 / 472;
    backface-visibility: hidden;
}

.product-ui-frame_productUIFrame__HXojZ::after {
        content: "";
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: inherit;
        box-shadow: var(--shadow-productui-frame-inset);
        z-index: var(--product-ui-z-index-border);
    }

.product-ui-frame_productUIAppFrame__RzIDx {
    --product-frame-border-radius: 0;

    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    box-shadow: var(--shadow-elevated);
}

.product-ui-frame_lg__scvcL {
    --product-ui-inline-size: 68rem;
    --product-ui-block-size: 34.375rem; /* 550px */
}

.product-ui-frame_baseRadius__t58JH {
    --product-frame-border-radius: var(--border-radius-base);
}

@container style (--is-dev-env: true) {
    .product-ui-frame_productFrame__02sSq {
        pointer-events: initial;
    }

        .product-ui-frame_productFrame__02sSq::after {
            pointer-events: initial;
        }
}

.product-ui-member-list_container__8dIBY {
    border-bottom-right-radius: var(--border-radius-xl);
    border: 1px solid var(--elevated-border);
    background: var(--elevated-background);
    padding: var(--space-24) var(--space-16);
}

.product-ui-member-list_controls__nTaFS {
    margin-block-start: var(--space-24);
    margin-block-end: var(--space-32);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-ui-member-list_controls__nTaFS .product-ui-member-list_fieldSkeleton__VJuIB {
        inline-size: 200px;
    }

.product-ui-member-list_members__aqOMr > span {
        display: flex;
        margin-block-end: var(--space-16);
    }

.product-ui-member-list_members__aqOMr hr {
        margin-block-start: var(--space-4);
        margin-block-end: var(--space-4);
    }

.product-ui-grid-gallery_gridGallery__tVjXc {
    --image-size: 128px;
    --container-size: 152px;

    grid-area: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    transform: translateY(4%) rotate(-3deg);
    transform-origin: center;
}

.product-ui-grid-gallery_gridGalleryRow__f6h3X {
    --gap-size: var(--space-16);
    display: flex;
    gap: var(--gap-size);
    transform: translateX(calc(var(--container-size) * -0.8));
}

.product-ui-grid-gallery_gridGalleryRow__f6h3X:nth-child(odd) {
    transform: translateX(calc((var(--container-size) + var(--gap-size)) * -1.25));
}

.product-ui-grid-gallery_gridGalleryItem__1XrFw {
    box-sizing: content-box;
    flex: 0 0 var(--image-size);
    aspect-ratio: 1;
    padding: var(--space-12);
    overflow: hidden;
    background-color: var(--neutral);
    border-radius: var(--border-radius-full);
    border: 1px solid var(--greytransparent-300);
    box-shadow: var(--productui-shadow-elevated);
    transform: rotate(3deg);
}

.product-ui-grid-gallery_gridGalleryItem__1XrFw img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
    }

.product-ui-scheduler-context-menu_schedulerContextMenu__dyOoF {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    padding: var(--space-12);
}

    .product-ui-scheduler-context-menu_schedulerContextMenu__dyOoF hr {
        margin: 0 0 var(--space-4) 0;
    }

.product-ui-scheduler-context-menu_dateControls__yS82_ {
    display: flex;
    gap: var(--space-4);
    justify-content: center;
}

.product-ui-scheduler-context-menu_calendar__c7fhp > p {
        font-weight: var(--minor-font-weight);
    }

.product-ui-scheduler-context-menu_calendar__c7fhp {

    --right-flex: flex-end;
}

.product-ui-scheduler-context-menu_calendar__c7fhp > p {
    font-weight: var(--minor-font-weight);
}

.product-ui-scheduler-context-menu_calendarGrid__VXCjN {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: var(--space-4);
    gap: var(--space-4);

    padding-inline: var(--space-12);
    padding-block-start: 0;
}

.product-ui-scheduler-context-menu_weekdayHeaders__quxni {
    text-align: center;
    color: var(--display-onlight-tertiary);
}

.product-ui-scheduler-context-menu_day__pP3D4 {
    display: flex;
    align-items: center;
    justify-content: center;
    block-size: 28px;
}

.product-ui-scheduler-context-menu_today__vWx_M {
    color: var(--productui-meta-red);
    font-weight: var(--font-weight-medium);
}

.product-ui-scheduler-context-menu_selected__gnnra {
    margin-inline: 3px;
    background-color: var(--productui-meta-red);
    border-radius: var(--border-radius-full);
}

.product-ui-scheduler-context-menu_selected__gnnra p {
        color: #fff;
    }

.product-ui-scheduler-context-menu_hidden__cazko {
    visibility: hidden;
}

.product-ui-scheduler-context-menu_weekend__j6Sf_ {
    color: var(--display-onlight-tertiary);
}

.container-two-column_containerTwoColumns__qq6oQ {
    --stacked: [full-start col1-start] minmax(0, 1fr) [col1-end col2-start] minmax(0, 1fr)
        [col2-end full-end];
    --sideBySide: [full-start col1-start col2-start] minmax(0, 1fr) [full-end col1-end col2-end];

    display: grid;
    grid-template-columns: var(--template, var(--sideBySide));
    grid-gap: var(--space-48);
    gap: var(--space-48);
}

    @media (min-width: 769px) {

    .container-two-column_containerTwoColumns__qq6oQ.container-two-column_breakAbove_md__vq7sl {
            --template: var(--stacked)
    }
        }

    @media (min-width: 960px) {

    .container-two-column_containerTwoColumns__qq6oQ.container-two-column_breakAbove_lg__0GrZm {
            --template: var(--stacked)
    }
        }

    @media (min-width: 1120px) {

    .container-two-column_containerTwoColumns__qq6oQ.container-two-column_breakAbove_xlg__pICXW {
            --template: var(--stacked)
    }
        }

.container-two-column_textContainerInner__zFADh {
    max-inline-size: var(--textContainerMaxInlineSize, initial);
    margin-inline: auto;
}

@media (min-width: 960px) {
        .container-two-column_textContainerInner__zFADh.container-two-column_lg__rLR18 {
            --textContainerMaxInlineSize: 31.25rem; /* 500px */
        }

        .container-two-column_textContainerInner__zFADh.container-two-column_base__vzNSD {
            --textContainerMaxInlineSize: 25rem; /* 400px */
        }
    }

.logo-marquee_logoWrapper__ZBPzE {
    max-inline-size: 94.5rem;
    margin: 0 auto;
    position: relative;
    margin-block-start: var(--space-16);
}

    @media (max-width: 959px) {.logo-marquee_logoWrapper__ZBPzE {
        margin-block-start: var(--space-32)
}
    }

    .logo-marquee_logoWrapper__ZBPzE:first-child {
        margin-block-start: calc(
            -0.5 *
            clamp(var(--space-128), calc(5rem + 10vw), var(--space-192))
        );
    }

.logo-marquee_illustration__w53_Q {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 94.5rem;
    overflow: hidden;
}

.logo-marquee_illustration__w53_Q img {
        inline-size: 100%;
        min-inline-size: 50rem;
        block-size: auto;
        margin-inline-start: 50%;
        transform: translateX(-50%);
    }

.logo-marquee_logos__XISG4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    inline-size: 100%;
}

.custom-page_layout__ZT4Wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(var(--space-128), calc(5rem + 10vw), var(--space-192));
}

.custom-page_withoutPrefooter__V3CPd {
    padding-block-end: var(--space-128);
}

.arcade-embed_wrapper__jpjDP {
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: 100%;
}

    .arcade-embed_wrapper__jpjDP .arcade-embed_title__gLgH_ {
        --section-mb: 40px;
        margin-inline: var(--space-24);
    }

    @media (max-width: 768px) {

    .arcade-embed_wrapper__jpjDP .arcade-embed_title__gLgH_ {
            --section-mb: 20px
    }
        }

@media (min-width: 769px) {
    .arcade-embed_iframeContainer__wbh9y {
        border-radius: var(--border-radius-lg);
        border: 1px solid var(--ui-border);

        background-image: var(--bg-url);
        background-size: cover;
        background-position: center;
        overflow-y: hidden;
    }

    .arcade-embed_iframe__vfUIw {
        padding: var(--iframe-padding, var(--space-32));
        padding-block-end: 0;
        /* To hide the embed's bottom border, move the element down and hide overflow */
        margin-block-end: -8px;
    }
}

@media (max-width: 959px) {
    .arcade-embed_iframe__vfUIw {
        --iframe-padding: var(--space-16);
        padding-block-end: 0;
    }
}

@media (max-width: 768px) {
    .arcade-embed_iframe__vfUIw {
        --iframe-padding: 0;
    }
}

.scroll-section_scrollSection__KBJZI {
    width: 100%;
}

.scroll-section_scrollContainer__9CIKD {
    display: var(--display, none);
    align-self: flex-start;
    align-content: center;
}

@media (min-width: 769px) {

.scroll-section_scrollContainer__9CIKD {
        --display: "block"
}
    }

.mobile-list_mobileList__UMpz5 {
    padding-inline: var(--space-24);
    padding-block-end: var(--space-24);
    display: flex;
    flex-direction: column;
    gap: var(--space-12);
}

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC {
        position: relative;
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;
    }

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC .mobile-list_imageContainer__Lk7na {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: var(--card-border-radius);
            border-bottom-left-radius: var(--card-border-radius);
            border-right: 1px solid var(--box-outline-border);
            padding: var(--space-8);
        }

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC > a:hover {
                text-decoration: none;
            }

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC > a::after {
                position: absolute;
                content: "";
                inset: 0;
            }

.templates-section_heading__GUFVw {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

    .templates-section_heading__GUFVw > p {
        max-inline-size: 40.625rem; /* 650px */
        color: var(--display-onlight-secondary);
    }

@media (max-width: 768px) {
    .templates-section_heading__GUFVw {
        align-items: flex-start;
        text-align: left;
    }
}

.scrolling-block_radioGroupWrapper__2uB1k {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

    .scrolling-block_radioGroupWrapper__2uB1k a {
        align-self: center;
    }

.two-columns_sectionContainer__W66Od {
    inline-size: 100%;
}

    .two-columns_sectionContainer__W66Od a {
        align-self: flex-start;
    }

.two-columns_twoColumn__CRv_G {
    align-items: center;
}

.two-columns_imageWrapper__lCWj7 img {
        inline-size: 100%;
        block-size: auto;
    }

.two-columns_videoWrapper__2ISmo {
    display: grid;
    place-items: center;
    inline-size: 100%;
    background-color: var(--ui-background);
    border-radius: var(--border-radius-2xl);
    overflow: hidden;
}

.two-columns_videoWrapper__2ISmo > *,
    .two-columns_videoWrapper__2ISmo::after {
        grid-area: 1 / 1;
    }

.two-columns_videoWrapper__2ISmo .two-columns_videoThumbnail__bJOYT {
        inline-size: 100%;
        block-size: auto;
        -o-object-fit: cover;
           object-fit: cover;
    }

.two-columns_videoWrapper__2ISmo .two-columns_video__fuaNm {
        inline-size: 100%;
        aspect-ratio: 16 / 9;
    }

.two-columns_videoWrapper__2ISmo::after {
        inline-size: calc(100% - 1px);
        block-size: calc(100% - 1px);
        content: "";
        box-shadow: var(--shadow-productui-frame-inset);
        border-radius: inherit;
        pointer-events: none;
    }

.two-columns_overlayButton__rgp3m {
    box-shadow: var(--shadow-overlay);
    z-index: 20;
}

.two-columns_right__nTgyg {
    inline-size: 100%;
    position: relative;
}

.reviews-section_reviewsContainer__tTtaH {
    display: flex;
    flex-direction: column;
    max-inline-size: 800px;
    text-align: center;
    margin-inline: auto;
    position: relative;
}

    .reviews-section_reviewsContainer__tTtaH .reviews-section_note___OT6J {
        color: var(--display-onlight-secondary);
    }

    .reviews-section_reviewsContainer__tTtaH .reviews-section_awardsContainer__VSdB7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        gap: var(--gap, var(--space-48));
    }

    @media (max-width: 768px) {

    .reviews-section_reviewsContainer__tTtaH .reviews-section_awardsContainer__VSdB7 {
            --gap: var(--space-32)
    }
        }

    @media (max-width: 480px) {

    .reviews-section_reviewsContainer__tTtaH .reviews-section_awardsContainer__VSdB7 {
            --gap: var(--space-24)
    }
        }

@media (min-width: 769px) {

.reviews-section_awardMiddle__wrBvO {
        padding-block-end: var(--space-24)
}
    }

.reviews-section_noteContainer__lW9z_ {
    position: absolute;
    bottom: calc(-1 * var(--space-64));
    inline-size: 100%;
    padding-inline: var(--space-12);
}

.social-proof_wrapper__NSI8P {
    inline-size: 100%;
    max-inline-size: 94.5rem;
    margin: 0 auto;
    position: relative;
    margin-block-start: var(--space-16);
}

    @media (max-width: 959px) {.social-proof_wrapper__NSI8P {
        margin-block-start: var(--space-32)
}
    }

    .social-proof_wrapper__NSI8P:first-child {
        margin-block-start: calc(
            -0.5 *
            clamp(var(--space-128), calc(5rem + 10vw), var(--space-192))
        );
    }

.social-proof_illustration__WELSL {
    margin-inline: auto;
    inline-size: 100%;
    overflow: hidden;
}

.social-proof_illustration__WELSL img {
        inline-size: 94.5rem;
        block-size: auto;
        margin-inline-start: 50%;
        transform: translateX(-50%);
    }

.social-proof_content__XOV47 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    inline-size: 100%;
}

.social-proof_note__De0YA {
    position: absolute;
    max-inline-size: 330px;
    left: 50%;
    transform: translateY(-70%);
}

@media (max-width: 1120px) {

.social-proof_note__De0YA {
        transform: translateY(-40%)
}
    }

@media (max-width: 768px) {

.social-proof_note__De0YA {
        transform: translateX(-50%) translateY(-25%)
}
    }

.publication-quote_grid__tnAe_ {
    --gap: var(--space-24);

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: var(--gap, var(--space-24));
    gap: var(--gap, var(--space-24));
    inline-size: -moz-fit-content;
    inline-size: fit-content;
    margin-inline: auto;
}

.publication-quote_publicationQuote__Lm18g {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-inline-size: 319px;
    block-size: 136px;
    padding: 0 var(--space-24);
    color: var(--display-onlight-primary);
    text-align: center;
}

.publication-quote_publicationQuote__Lm18g::after {
        content: "";
        position: absolute;
        background-color: var(--ui-border);
        inline-size: 1px;
        display: block;
        block-size: 100%;
        inset: 0 0 0 calc(var(--gap) * -0.5);
    }

.publication-quote_publicationQuote__Lm18g:first-child::after {
        opacity: 0;
    }

@media (max-width: 959px) {
        .publication-quote_publicationQuote__Lm18g:first-child::after {
            opacity: 1;
        }
    }

.publication-quote_blockquote___wuqY {
    display: flex;
    flex: 0 0 72px;
    align-items: center;
    justify-content: center;
    text-wrap: balance;
}

.publication-quote_logoContainer___PtHg {
    max-block-size: 41px;
}

.publication-quote_logoContainer___PtHg img {
        block-size: 100%;
        inline-size: 100%;
        -o-object-fit: contain;
           object-fit: contain;
    }

.feature-teams_teamsLayout__Ij_4n {
    display: flex;
    flex-direction: column;
    gap: clamp(var(--space-128), calc(5rem + 10vw), var(--space-192));
}

@media (max-width: 768px) {

.feature-teams_allDevices__EY2G5 {
        text-align: center
}
    }

.feature-teams_devicesSpotIllustration__RXAzn {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 820px;
    overflow: hidden;
}

.feature-teams_devicesSpotIllustration__RXAzn img {
        inline-size: 100%;
        min-inline-size: 450px;
        block-size: auto;
        margin-inline-start: 50%;
        transform: translateX(-50%);
    }

.feature-teams_workspaceSection__CGI9d .feature-teams_radioGroupWrapper__8Mgqh {
        display: flex;
        flex-direction: column;
        gap: var(--space-24);
    }

.feature-teams_workspaceSection__CGI9d .feature-teams_radioGroupWrapper__8Mgqh a {
            align-self: center;
        }

.feature-teams_templatesContainer__Lddj0 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.feature-teams_templatesContainer__Lddj0 .feature-teams_grid__80p_0 {
        display: flex;
        flex-direction: var(--grid-direction, row);
        gap: var(--gap, var(--space-32));
    }

@media (max-width: 768px) {

.feature-teams_templatesContainer__Lddj0 .feature-teams_grid__80p_0 {
            --grid-direction: column;
            --gap: var(--space-24)
    }
        }

.feature-teams_exploreContainer__ePsau {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.feature-teams_exploreContainer__ePsau .feature-teams_grid__80p_0 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(144px, 100%), 1fr));
        grid-gap: var(--space-12);
    }

.feature-teams_prefooter__WNNBb {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature-teams_prefooter__WNNBb .feature-teams_buttons__jmDFW {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-12);
    }

.feature-teams_prefooterBackground__Ho0tN {
    background: linear-gradient(0deg, var(--composition-background-red) 58%, transparent 100%);
    width: 100%;
}

.feature-teams_faq__p9k7d {
    margin-block-end: calc(-1 * var(--space-64));
}

.feature-header_imageContainer__PtCXS {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 1200px;
    overflow: hidden;
}

.feature-header_image__qbjIi {
    inline-size: 100%;
    min-inline-size: 460px;
    block-size: auto;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-block-end: calc(-1 * var(--space-16));
}

.feature-header_button__bqKSH {
    margin-block-start: var(--space-24);
}

.feature-header_lineThrough__c_aPs {
    text-decoration: line-through;
    color: var(--display-onlight-tertiary);
    /**
     * Tertiary color is based on opacity.
     * With strikethrough, this results in different colors
     * of text/line and their intersections.
     * Below, we calculate the absolute value of the tertiary color
     * by mixing the primary with page background color at tertiary opacity level.
     */
}

@supports (color: color-mix(in lch, red, blue)) {
.feature-header_lineThrough__c_aPs {
    color: color-mix(
        in srgb,
        var(--display-onlight-primary) 50%,
        var(--composition-background-neutral) 50%
    );
}
}

.icon-card_iconCard__ZtmCa {
    --padding: var(--space-32) var(--space-16);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-12);
    transition: var(--card-active-transition);
}

    .icon-card_iconCard__ZtmCa:hover {
        text-decoration: none;
        border: 1px solid var(--card-border-onhover);
        box-shadow: var(--shadow-card-hover);
        background-color: var(--card-background-onhover);
    }

    .icon-card_iconCard__ZtmCa {

    padding: var(--padding);
    background-color: var(--card-background-idle);
    border: 1px solid var(--card-border-idle);
    box-shadow: var(--shadow-card-idle);
    border-radius: var(--card-border-radius);
}

    .icon-card_iconCard__ZtmCa img {
        max-inline-size: 120px;
        inline-size: auto;
        block-size: 125px;
        -o-object-fit: contain;
           object-fit: contain;
    }

    .icon-card_iconCard__ZtmCa p {
        height: calc(2em * var(--line-height));
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: 960px) {.icon-card_iconCard__ZtmCa {
        --padding: var(--space-32) var(--space-24)
}
    }

.SectionHeader_sectionHeader__mOM3u {
    display: flex;
    flex-direction: column;
    gap: var(--gap, var(--space-8));
    margin-block-end: var(--section-mb, var(--space-48));
    text-align: var(--text-align, center);
}

    @media (max-width: 768px) {.SectionHeader_sectionHeader__mOM3u {
        text-align: var(--text-align-narrow, center)
}
    }

    @media (min-width: 769px) {.SectionHeader_sectionHeader__mOM3u {
        --section-mb: var(--space-64);
        max-inline-size: 673px
}
    }

.SectionHeader_title__4Cn9r {
    color: var(--display-onlight-primary);
}

.SectionHeader_subtitle__Zc0v4 {
    max-inline-size: 40.625rem;
    color: var(--display-onlight-secondary);
}

.SectionHeader_cta__gAbMv {
    margin-block-start: var(--space-16);
}

.SectionHeader_lg__o4FfP {
    --gap: var(--space-24);
}

@media (max-width: 768px) {

.SectionHeader_lg__o4FfP {
        --gap: var(--space-16)
}
    }

.icon-card_grid__dFQGY {
    inline-size: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(230px, 100%), 1fr));
    grid-gap: var(--space-12);
    gap: var(--space-12);
    align-items: center;
}

.icon-card_iconCard__zyFdH {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    gap: var(--gap, var(--space-16));
}

@media (max-width: 768px) {

.icon-card_iconCard__zyFdH {
        --gap: var(--space-12)
}
    }

.icon-card_textContainer___DS87 p:last-child {
        color: var(--display-onlight-secondary);
    }

.icon-card_link___AGYK {
    outline: 0;
}

.icon-card_link___AGYK:hover {
        text-decoration: none;
    }

.icon-card_link___AGYK::after {
        position: absolute;
        content: "";
        inset: 0;
    }

.more-info-section_container__NO_bD {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--space-48);
}

.more-than-the-app-section_container__sE1jS .more-than-the-app-section_headerWrapper__JEd_I {
        display: flex;
        flex-direction: column;
        gap: var(--space-48);
        align-items: center;
    }
    .more-than-the-app-section_container__sE1jS .more-than-the-app-section_imageWrapper__Fr_Ty {
        max-inline-size: 296px;
    }
    .more-than-the-app-section_container__sE1jS .more-than-the-app-section_imageWrapper__Fr_Ty img {
            inline-size: 100%;
            block-size: auto;
        }
    .more-than-the-app-section_container__sE1jS .more-than-the-app-section_grid__zKjAw {
        display: flex;
        gap: var(--space-64);
    }
    @media (max-width: 768px) {
    .more-than-the-app-section_container__sE1jS .more-than-the-app-section_grid__zKjAw {
            flex-direction: column;
            gap: var(--space-32)
    }
        }
    .more-than-the-app-section_container__sE1jS .more-than-the-app-section_item__PG6Db {
        display: flex;
        flex-direction: column;
        gap: var(--space-8);
    }
    .more-than-the-app-section_container__sE1jS .more-than-the-app-section_item__PG6Db p:nth-child(2) {
            color: var(--display-onlight-secondary);
        }

.card-animations_enter__NJMVh {
    z-index: 2;
    transform: translateY(calc(var(--space-16) * -1)) scale(0.95);
    transform-origin: top;
    box-shadow: none;
}

.card-animations_enterActive__3AzSy {
    transform: translateY(0) scale(1);
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12);
    transition: var(--animation-duration-600) var(--animation-ease-base) 50ms;
    transition-property: transform opacity box-shadow;
}

.card-animations_exit__Rei__ {
    z-index: 3;
    opacity: 1;
}

.card-animations_exitActive__j89am {
    opacity: 0;
    transform: translateY(var(--space-32)) scale(1);
    transition: var(--animation-duration-350) ease-out;
    transition-property: transform opacity;
    pointer-events: none;
}

.prefooter_prefooterBackground__yy6tm {
    background: linear-gradient(
        0deg,
        var(--background-color, --composition-background-yellow) 58%,
        transparent 100%
    );
    inline-size: 100%;
    padding-block-start: var(--space-192);
}

    @media (max-width: 768px) {.prefooter_prefooterBackground__yy6tm {
        padding-block-start: var(--space-128)
}
    }

.prefooter_leftColumn__uXejU > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-32);

    block-size: 100%;
    max-inline-size: 400px;
}

@media (max-width: 768px) {

.prefooter_leftColumn__uXejU > div {
        text-align: center;
        --button-align: center
}
    }

.prefooter_leftColumn__uXejU > div a {
        align-self: var(--button-align, flex-start);
    }

.prefooter_rightColumn__kT_9V {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prefooter_tweetsContainer__NUVDM {
    block-size: 380px;
    inline-size: var(--tweets-container-width, 500px);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-48);
}

@media (max-width: 768px) {

.prefooter_tweetsContainer__NUVDM {
        --tweets-container-width: 100%
}
    }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi {
        inline-size: 100%;
        block-size: calc(100% - 40px);
        position: relative;
    }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi > div {
            position: absolute;
            top: 0;
            left: 0;
            inline-size: 100%;
            block-size: calc(100% - var(--space-32));
        }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi > .prefooter_dummyCard__Rd1wI {
            left: var(--space-24);
            inline-size: calc(100% - var(--space-48));
            background-color: white;
            box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12);
            border-radius: var(--border-radius-base);
        }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi > .prefooter_nextCard__tdUB1 {
            left: var(--space-12);
            inline-size: calc(100% - var(--space-24));
            top: var(--space-16);
        }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi > .prefooter_nextCard__tdUB1 .prefooter_tweet__Y2I6w {
                block-size: calc(100% - var(--space-32));
            }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi > .prefooter_currentCard__ZTrhh {
            top: var(--space-32);
        }

.prefooter_tweetsContainer__NUVDM .prefooter_tweets__KYMVi .prefooter_tweet__Y2I6w > div {
                /* override the default tweek card margin */
                margin-block-end: var(--space-24);
            }

.confirmation_confirmation__GoQQv {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-block-size: calc(80vh - (var(--space-64) + var(--space-96)));
    max-inline-size: 450px;
    inline-size: 100%;
    gap: var(--space-48);
}

    .confirmation_confirmation__GoQQv .confirmation_imageContainer__Vt9mP {
        inline-size: 150px;
        block-size: 103px;
    }

    .confirmation_confirmation__GoQQv .confirmation_imageContainer__Vt9mP img {
            max-inline-size: 100%;
        }

.confirmation_link__WqjmX {
    color: var(--display-onlight-color-blue);
    text-decoration: none;
}

.confirmation_link__WqjmX b {
        color: var(--display-onlight-color-blue);
    }

.confirmation_snippetContainer__1u8xZ {
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--box-outline-border);
    background: var(--box-outline-background);
    padding: var(--space-16);
    margin-block-start: var(--margin-top, var(--space-16));
}

@media (max-width: 768px) {

.confirmation_snippetContainer__1u8xZ {
        --margin-top: 0
}
    }

.confirmation_buttons__WF4_F {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-12);
}

@keyframes animations_fadein__gz_tI {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes animations_fadeout__c4_UY {
    from {
        opacity: 1;
    }
    to {
        opacity: 2;
    }
}

@keyframes animations_slideIn__WmSMD {
    0% {
        opacity: 0;
        transform: translateY(-12px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animations_slideIn__WmSMD {
    opacity: 0;
    transform: translateY(-12px);
    animation: animations_slideIn__WmSMD var(--animation-duration-350) forwards var(--animation-ease-base);
}

.animations_fadeIn__L2OP_ {
    opacity: 0;
    animation: animations_fadein__gz_tI var(--animation-duration-600) both var(--animation-ease-base);
}

.render-rich-text_container__y_3My {
    animation-delay: 0.5s;
}

    .render-rich-text_container__y_3My.render-rich-text_withBorder__kHTG7 {
        border-radius: var(--border-radius-lg);
        border: 1px solid var(--box-outline-border);
        background: var(--box-outline-background);
        padding: var(--space-16);
    }

    .render-rich-text_container__y_3My > p:not(:last-child) {
        margin-block-end: var(--space-16);
    }

    .render-rich-text_container__y_3My img {
        inline-size: 100%;
        max-inline-size: 416px;
        height: auto;
    }

    .render-rich-text_container__y_3My ul,
    .render-rich-text_container__y_3My ol {
        padding-block-start: 0;
        padding-block-end: var(--space-16);
    }

    .render-rich-text_container__y_3My li > p {
        display: inline;
    }

    .render-rich-text_container__y_3My li:before {
        content: "-";
        margin-inline-end: var(--space-4);
    }

    .render-rich-text_container__y_3My blockquote {
        margin-block-start: var(--space-16);
        padding: var(--space-8);
        background-color: var(--color-gray-04);
        border-radius: var(--border-radius-lg);
    }

    .render-rich-text_container__y_3My p:empty {
        display: none;
    }

.choice-form_choiceForm__ZDeQf {
    animation-delay: 0.5s;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(157px, 100%), 191px));
    grid-gap: var(--space-12);
    gap: var(--space-12);
    justify-content: center;
}

.mobile-stepper_mobileStepper__iOdac {
    display: flex;
    align-items: center;
    gap: var(--space-24);
}

    .mobile-stepper_mobileStepper__iOdac .mobile-stepper_progressContainer__CW2sD {
        block-size: var(--space-8);
        inline-size: 100%;
        border-radius: var(--border-radius-sm);
        overflow: hidden;
        position: relative;
    }

    .mobile-stepper_mobileStepper__iOdac .mobile-stepper_backgroundLine__Nb2PT {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--border-radius-sm);
        background-color: var(--ui-background);
    }

    .mobile-stepper_mobileStepper__iOdac .mobile-stepper_progressLine__wjHKJ {
        position: absolute;
        top: 0;
        left: 0;
        inline-size: 50%;
        bottom: 0;
        border-radius: var(--border-radius-sm);
        background-color: var(--accents-red);
    }

.platform-header_container__cSO5f {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-64);
}

.platform-header_header___Y_VH {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.platform-header_copy__LNEPE {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-8);
    text-align: center;
}

.platform-header_copy__LNEPE .platform-header_subheader__ydOpW {
        max-inline-size: 650px;
        color: var(--display-onlight-secondary);
    }

.platform-header_copy__LNEPE .platform-header_subheader__ydOpW a {
            text-decoration: none;
        }

.platform-header_copy__LNEPE .platform-header_subheader__ydOpW a:hover {
            text-decoration: underline;
        }

.header_imageWrapper__bUkPo {
    display: flex;
    justify-content: center;
    padding-block-start: var(--space-32);
}

    .header_imageWrapper__bUkPo img {
        inline-size: 100%;
        max-inline-size: 776px;
        height: auto;
    }

.one-column-section_container___9O8s {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.one-column-section_imageWrapper__U4DpL {
    inline-size: 100%;
    max-inline-size: 547px;
}

.one-column-section_imageWrapper__U4DpL img {
        inline-size: 100%;
        block-size: auto;
    }

.three-column-section_container__t2Kep {
    display: flex;
    flex-direction: var(--column-direction, row);
    align-items: center;
    gap: var(--gap, var(--space-96));

    inline-size: 100%;
}

    @media (max-width: 768px) {.three-column-section_container__t2Kep {
        --column-direction: column;
        justify-content: center
}
    }

    @media (max-width: 1120px) {.three-column-section_container__t2Kep {
        --gap: var(--space-48)
}
    }

    @media (max-width: 768px) {.three-column-section_container__t2Kep {
        --gap: var(--space-96)
}
    }

.three-column-section_featureBox__oYy9i {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);

    inline-size: 100%;
    max-inline-size: 300px;
}

.three-column-section_text__0uSCx {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.three-column-section_subtitle__YhzoK {
    color: var(--display-onlight-secondary);
}

.layout_layout__y8rWt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-96);
}

.flexible-views_illustrationContainer__9I66o {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 680px;
    overflow: hidden;
}

.flexible-views_illustration__PR3JY {
    inline-size: 100%;
    min-inline-size: 450px;
    block-size: auto;
    margin-inline-start: 50%;
    transform: translateX(-50%);
}

.flexible-views_headerContainer__w8fdH {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert_alert__LJKCv {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    -moz-column-gap: var(--space-8);
         column-gap: var(--space-8);
    min-block-size: var(--tube-height-base);
    padding: var(--space-8) var(--space-16);

    background-color: var(--box-default-background);
    border-radius: var(--border-radius-lg);
    text-align: center;
}

.video-block_videoWrapper__5JYEk {
    display: grid;
    place-items: center;
    inline-size: 100%;
    background-color: var(--ui-background);
    border-radius: var(--border-radius-2xl);
    overflow: hidden;
}

    .video-block_videoWrapper__5JYEk > *,
    .video-block_videoWrapper__5JYEk::after {
        grid-area: 1 / 1;
    }

    .video-block_videoWrapper__5JYEk .video-block_videoThumbnail__c3gBl {
        inline-size: 100%;
        block-size: auto;
        -o-object-fit: cover;
           object-fit: cover;
    }

    .video-block_videoWrapper__5JYEk .video-block_video__w9CXO {
        inline-size: 100%;
        aspect-ratio: 16 / 9;
    }

    .video-block_videoWrapper__5JYEk::after {
        inline-size: calc(100% - 1px);
        block-size: calc(100% - 1px);
        content: "";
        box-shadow: var(--shadow-productui-frame-inset);
        border-radius: inherit;
        pointer-events: none;
    }

.video-block_overlayButton__eiI2L {
    box-shadow: var(--shadow-overlay);
    z-index: 20;
}

.video-block_alertSection__RF3iV {
    text-align: center;
}

.flexible-views-section_galleryContainer__HSE6W {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-24);
}

.flexible-views-section_tileGroupContainer__HRUwd {
    max-inline-size: 100vw;
}

.flexible-views-section_sceneContainer__R_UwF {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    margin: auto;
}

.flexible-views-section_sceneContainer__R_UwF > * {
        grid-column: 1;
        grid-row: 1;
    }

.flexible-views-section_scene__y6yuz {
    transition: opacity var(--animation-duration-600) var(--animation-ease-base);
    z-index: 2;
}

.flexible-views-section_scene__y6yuz.flexible-views-section_active__WG8Fh {
        opacity: 1;
    }

.flexible-views-section_scene__y6yuz:not(.flexible-views-section_active__WG8Fh) {
        opacity: 0;
    }

.flexible-views-section_illustrationContainer__BbeN2 {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 680px;
    overflow: hidden;
}

.flexible-views-section_illustration__NmUkd {
    inline-size: 100%;
    min-inline-size: 450px;
    block-size: auto;
    margin-inline-start: 50%;
    transform: translateX(-50%);
}

.flexible-views-section_headerContainer__Tz27E {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_footer__DivxD {
    margin-block-start: auto;
}

.header_header__2pZKc {
    display: flex;
    justify-content: space-between;
}

.icon-card_iconCard__RjGr_ {
    display: flex;
    flex-direction: column;
    position: relative;
    block-size: 100%;
}

.icon-card_link__N8_im {
    outline: 0;
}

.icon-card_link__N8_im:hover {
        text-decoration: none;
    }

.icon-card_link__N8_im::after {
        position: absolute;
        content: "";
        inset: 0;
    }

.icon-card_imageContainer__O_ZRu {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 7.8125rem;
    background-color: var(--bg-color, white);
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
}

.icon-card_imageContainer__O_ZRu img {
        -o-object-fit: cover;
           object-fit: cover;
    }

.icon-card_description__Ye2mt {
    color: var(--display-onlight-secondary);
    flex: 1 1;
}

.icon-card_lg__kkV4c .icon-card_imageContainer__O_ZRu {
    block-size: 11.5625rem;
}

.icon-card_topRight__EEM7p {
    position: absolute;
    top: 8px;
    right: 8px;
}

.marquee_container__rUbzZ {
    --gap: var(--space-16);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: var(--gap);
    -webkit-mask-image: linear-gradient(
        to right,
        transparent 0%,
        rgb(0, 0, 0) 10%,
        rgb(0, 0, 0) 90%,
        transparent 100%
    );
            mask-image: linear-gradient(
        to right,
        transparent 0%,
        rgb(0, 0, 0) 10%,
        rgb(0, 0, 0) 90%,
        transparent 100%
    );
    inline-size: 100%;
    overflow: hidden;
}

.marquee_marquee__MIa8l {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-around;
    gap: var(--gap);
    animation: marquee_marquee__MIa8l var(--duration) linear infinite;
}

@keyframes marquee_marquee__MIa8l {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(calc(-100% - var(--gap)));
        }
    }

.draft-mode-banner_draftInner__l5BuK {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.award_awardContainer__qfcZQ {
    --leaf-height: 60px;
    --leaf-width: 29px;

    display: flex;
    inline-size: -moz-max-content;
    inline-size: max-content;
}

    .award_awardContainer__qfcZQ p {
        color: var(--display-onlight-tertiary);
    }

    .award_awardContainer__qfcZQ .award_right__dga9M {
        transform: scale(1, 1) scale(-1, 1);
    }

    .award_awardContainer__qfcZQ .award_left__M840O,
    .award_awardContainer__qfcZQ .award_right__dga9M {
        block-size: var(--leaf-height);
        inline-size: var(--leaf-width);
    }

    .award_awardContainer__qfcZQ .award_content__Vknn_ {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: var(--space-8);
        width: 95px;
    }

@media (max-width: 480px) {
    .award_awardContainer__qfcZQ {
        --leaf-height: 51px;
        --leaf-width: 25px;
    }
}

.handwritten-box_handwrittenBox__iCTtq {
    display: flex;
}

    .handwritten-box_handwrittenBox__iCTtq p {
        padding: var(--space-8);
        text-align: center;
        transform: rotate(var(--text-tilt, 0));
        color: var(--display-onlight-secondary);
    }

    .handwritten-box_handwrittenBox__iCTtq svg {
        inline-size: auto;
    }

.handwritten-box_right__bDeyB {
    flex-direction: row;
    align-items: flex-end;
}

.handwritten-box_left__zrr7K {
    flex-direction: row-reverse;
    align-items: flex-end;
}

.handwritten-box_top__pJLTL {
    flex-direction: column-reverse;
    align-items: center;
}

.handwritten-box_bottom__tlHdc {
    flex-direction: column;
    align-items: center;
}

.reviews-counter_reviewsCounter__aETHI {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-4);
    inline-size: -moz-fit-content;
    inline-size: fit-content;
    color: var(--display-onlight-secondary);

    border-radius: var(--border-radius-base);
    border: 1px solid var(--box-outline-border);
    padding-inline: var(--space-4);
}

    .reviews-counter_reviewsCounter__aETHI > div:has(svg) {
        display: flex;
        align-items: center;
        padding: var(--space-8) var(--space-8) var(--space-8) var(--space-4);
        border-right: 1px solid var(--box-outline-border);
    }

